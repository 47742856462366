import {hexToRgba} from 'lib/stylesUtils'
import {makeStyles} from "@material-ui/core/styles";

const colored = (theme, color) => {
    const currentColor = theme.palette[color].main;

    return {
        "&:before": {
            background: hexToRgba(theme.palette.background.default, 0.5),
        },
        "&:after": {
            background:
                "linear-gradient(60deg," +
                hexToRgba(currentColor,.75) + ", " +
                hexToRgba(currentColor,.95) + "); ",
        },
        "&:after,&:before": {
            position: "absolute",
                zIndex: "1",
                width: "100%",
                height: "100%",
                display: "block",
                left: "0",
                top: "0",
                content: "''"
        }
    };
};

export default makeStyles((theme) => ({
    parallax: {
        height: "100vh",
        maxHeight: "1600px",
        overflow: "hidden",
        position: "relative",
        backgroundPosition: "50%",
        backgroundSize: "cover",
        margin: "0",
        padding: "0",
        border: "0",
        display: "flex",
        alignItems: "center",
    },
    filter: {},
    darkColor: {
        "&:before": {
            background: hexToRgba(theme.palette.common.black, 0.5),
        },
        "&:after,&:before": {
            position: "absolute",
            zIndex: "1",
            width: "100%",
            height: "100%",
            display: "block",
            left: "0",
            top: "0",
            content: "''"
        }
    },
    primaryColor: colored(theme, 'primary'),
    secondaryColor: colored(theme, 'secondary'),
    infoColor: colored(theme, 'info'),
    successColor: colored(theme, 'success'),
    warningColor: colored(theme, 'warning'),
    errorColor: colored(theme, 'error'),
    small: {
        height: "65vh",
        minHeight: "65vh",
        maxHeight: "650px"
    }
}));
