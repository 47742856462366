import React from "react";
import classnames from "classnames";

import {List, ListItem} from '@material-ui/core';
import useStyles from "styles/footerStyle";

export default () => {
    const classes = useStyles();

    return (
        <footer className={classes.footer}>
            <div className={classes.container}>
                <div className={classes.center}>
                    <List className={classes.list}>
                        <ListItem className={classes.inlineBlock}>
                        <span className={classes.block}>
                            {`© ${new Date().getFullYear()} Photonica.tech`}
                        </span>
                        </ListItem>
                        <ListItem className={classes.inlineBlock}>
                            <a href="mailto:info@photonica.tech" className={classes.block}>
                                e-mail: info@photonica.tech
                            </a>
                        </ListItem>
                    </List>
                </div>
            </div>
        </footer>
    );
}
