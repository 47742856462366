import {makeStyles} from '@material-ui/core/styles';
import commonStyle from 'styles/commonStyle';

export default makeStyles(theme => {
    const {container} = commonStyle(theme);

    return {
        footer: {
            padding: "32px 0 0",
            textAlign: "center",
            display: "flex",
            zIndex: "2",
            position: "relative",
            textDecoration: "none",
        },
        content: {
            textAlign: "left"
        },
        a: {
            backgroundColor: "transparent"
        },
        container: {
            minHeight: "50px",
            ...container,
        },
        list: {
            marginBottom: "0",
            padding: "0",
            marginTop: "0"
        },
        block: {
            color: "inherit",
            padding: "1rem",
            fontWeight: "500",
            fontSize: "12px",
            textTransform: "uppercase",
            borderRadius: "3px",
            textDecoration: "none",
            position: "relative",
            display: "block"
        },
        inlineBlock: {
            display: "inline-block",
            padding: "0px",
            width: "auto"
        },
        center: {
            display: "inline-block",
            float: "none"
        },
    };
});

