export const hexToRgbArray = (hexColor) => {
    hexColor = hexColor.replace("#", "");
    const hexRegex = /[0-9A-Fa-f]/g;

    if (!hexRegex.test(hexColor) || (hexColor.length !== 3 && hexColor.length !== 6)) {
        throw new Error(`${hexColor} is not a valid hex color.`);
    }

    let red, green, blue;
    if (hexColor.length === 3) {
        red = hexColor[0] + hexColor[0];
        green = hexColor[1] + hexColor[1];
        blue = hexColor[2] + hexColor[2];
    } else {
        red = hexColor[0] + hexColor[1];
        green = hexColor[2] + hexColor[3];
        blue = hexColor[4] + hexColor[5];
    }

    return ([
        parseInt(red, 16),
        parseInt(green, 16),
        parseInt(blue, 16)
    ]);
};

export const hexToRgb = (hexColor) => (
    `rgb( ${hexToRgbArray(hexColor).join(", ")} )`
);

export const hexToRgba = (hexColor, transparency) => (
    `rgba( ${hexToRgbArray(hexColor).join(", ")}, ${transparency.toString()} )`
);
