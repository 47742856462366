import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from 'react-router-dom';
import {createBrowserHistory} from 'history';
import reportWebVitals from './reportWebVitals';

import App from 'App';
import {CssBaseline} from '@material-ui/core';

import {ThemeProvider} from '@material-ui/core/styles';
import theme from 'styles/theme';

const history = createBrowserHistory();

ReactDOM.render(
    <React.StrictMode>
        <Router history={history}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
