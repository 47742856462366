import {createMuiTheme} from "@material-ui/core/styles";

export default createMuiTheme({
    palette: {
        primary: {
            main: "#133aac",
            light: "#5a64df",
            dark: "#00157c",
        },
        secondary: {
            main: "#ffd700",
            light: "#ffff52",
            dark: "#c7a600",
        },
        error: {
            main: "#ff4100",
            light: "#ff783a",
            dark: "#c30000",
        },
        warning: {
            main: "#ff7a00",
            light: "#ffab42",
            dark: "#c54b00",
        },
        success: {
            main: "#3bda00",
            light: "#7cff4f",
            dark: "#00a700",
        },
        info: {
            main: "#6d87d6",
            light: "#a0b7ff",
            dark: "#395aa4",
        },
        text: {
            primary: "#000",
            secondary: "#1c3838",
            onDarkPrimary: "#fff",
            onDarkSecondary: "#c2c3c1",
            disabled: "908e8e",
        },
        divider: "#908e8e",
        background: {
            paper: "#fff",
            default: "#c2c3c1",
        },
    },
    typography: {
        fontFamily: '"IBM Plex Serif","Roboto", "Helvetica", "Arial", sans-serif',
        htmlFontSize: 16,
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontWeight: 700, //extra bold
            fontSize: "2.125rem", //34px
            lineHeight: 1.17, //48px
            letterSpacing: "-0.09px",
        },
        h2: {
            fontWeight: 700, //extra bold
            fontSize: "1.500rem", //24px
            lineHeight: 1.24, //36px
            letterSpacing: "-0.09px",
        },
        h3: {
            fontWeight: 700, //extra bold
            fontSize: "1.375rem", //22px
            lineHeight: 1.2, //32px
            letterSpacing: "-0.09px",
        },
        h4: {
            fontWeight: 700, //bold
            fontSize: "1.250rem", //20px
            lineHeight: 1.12, //27px
            letterSpacing: "0em",
        },
        h5: {
            fontWeight: 700, //bold
            fontSize: "1.125rem", //18px
            lineHeight: 1.24, //27px
            letterSpacing: "0em",
        },
        h6: {
            fontWeight: 700, //bold
            fontSize: "1rem", //16px
            lineHeight: 1.24, //24px
            letterSpacing: "0em",
        },
        subtitle1: {
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.75,
            letterSpacing: "0.00938em",
        },
        subtitle2: {
            fontWeight: 500,
            fontSize: "0.875rem",
            lineHeight: 1.57,
            letterSpacing: "0.00714em",
        },
        body1: {
            fontWeight: 400,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: "0em",
        },
        body2: {
            fontWeight: 400,
            fontSize: "0.875rem",
            lineHeight: 1.5,
            letterSpacing: "0.007em",
        },
        button: {
            fontWeight: 700,
            fontSize: "1rem",
            lineHeight: 1.5,
            letterSpacing: "0.006em",
            textTransform: "uppercase",
        },
        caption: {
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 1.66,
            letterSpacing: "0.03333em",
        },
        overline: {
            fontWeight: 400,
            fontSize: "0.75rem",
            lineHeight: 2.66,
            letterSpacing: "0.08333em",
            textTransform: "uppercase",
        },
    },
});
