import {useEffect} from "react";
import classNames from "classnames";

import {AppBar, Toolbar, Link} from "@material-ui/core";

import useStyles from "styles/siteNavbarStyle";

export default () => {
    const changeColorOnScrollHeight = 128;
    const classes = useStyles();

    useEffect(() => {
        window.addEventListener("scroll", headerColorChange);

        return function cleanup() {
            window.removeEventListener("scroll", headerColorChange);
        };
    });

    const headerColorChange = () => {
        const windowsScrollTop = window.pageYOffset;
        if (windowsScrollTop > changeColorOnScrollHeight) {
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes["transparent"]);
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes["background"]);
        } else {
            document.body
                .getElementsByTagName("header")[0]
                .classList.add(classes['transparent']);
            document.body
                .getElementsByTagName("header")[0]
                .classList.remove(classes["background"]);
        }
    };

    const appBarClasses = classNames({
        [classes.appBar]: true,
        [classes['transparent']]: true,
    });

    return (
        <AppBar className={appBarClasses}>
            <Toolbar className={classes.container}>
                <Link href="/" className={classes.title}>
                    <img src={process.env.PUBLIC_URL + "/img/logo.svg"} alt="Logo"/>
                </Link>
            </Toolbar>
        </AppBar>
    );
};
