import {makeStyles} from "@material-ui/core/styles";
import commonStyle from "styles/commonStyle";

export default makeStyles(theme => {
    const {container} = commonStyle(theme);

    return {
        appBar: {
            display: "flex",
            width: "100%",
            position: "fixed",
            padding: "10px 0 10px",
            marginBottom: "20px",
            transition: "all 150ms ease 0s",
        },
        container: {
            ...container,
            minHeight: "50px",
        },
        background: {
            backgroundColor: theme.palette.primary.main,
        },
        transparent: {
            backgroundColor: "transparent !important",
            boxShadow: "none",
        },
    };
});

