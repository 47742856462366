import {makeStyles} from "@material-ui/core/styles";
import commonStyle from "styles/commonStyle";

export default makeStyles(theme => {
    const {container} = commonStyle(theme);

    return {
        container: {
            ...container,
            color: theme.palette.common.white,
            zIndex: "2",
            textAlign: "center",
        },
        title: {
            display: "inline-block",
            position: "relative",
            textDecoration: "none",
        },
        subtitle: {
            margin: "10px auto 0",
        },
        main: {
            position: "relative",
            zIndex: "3",
            "@media (max-width: 830px)": {
                marginLeft: "10px",
                marginRight: "10px"
            },
            margin: "-90px 30px 0",
        },
        infoArea: {
            padding: "70px 0",
        },
        infoTitle: {
            margin: "20px auto 8px",
        },
    }
});

