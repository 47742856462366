import {useEffect} from "react";

import {Card, CardContent, Grid, Typography} from "@material-ui/core";
import SiteNavbar from "components/SiteNavbar";
import Footer from "components/Footer";
import Parallax from "components/Parallax";

import useStyles from "styles/homeStyle";

export default () => {
    const classes = useStyles();

    useEffect(() => {
        window.scrollTo(0, 0);
        document.body.scrollTop = 0;
    });

    return (
        <div className={classes.root}>
            <SiteNavbar/>
            <Parallax image={process.env.PUBLIC_URL + "/img/bg.svg"} filter="primary" small>
                <div className={classes.container}>
                    <Typography variant="h1" className={classes.title}>
                        Breakthrough Nano-Light Technology
                    </Typography>
                    <Typography variant="h4" className={classes.subtitle}>
                        (currently in a “stealth mode”)
                    </Typography>
                </div>
            </Parallax>
            <div className={classes.main}>
                <Card>
                    <CardContent className={classes.container}>
                        <Grid container spacing={8} justify="center" className={classes.infoArea}>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h2" color="textSecondary" className={classes.infoTitle}>
                                    Who we are
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    We are a team of academic researchers and industry professionals with a vast
                                    background in optical devices and nanofabrication.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h2" color="textSecondary" className={classes.infoTitle}>
                                    What we do
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    We combine our cross-disciplinary expertise to enable groundbreaking
                                    technology which will make light serve for the benefit of humanity.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography variant="h2" color="textSecondary" className={classes.infoTitle}>
                                    How we do it
                                </Typography>
                                <Typography variant="body1" color="textPrimary">
                                    We use nanostructures to manipulate light. Our approach enables
                                    unprecedented level of control over a wide range of light properties.
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
            <Footer/>
        </div>
    );
}
